import {Observable, Subject} from 'rxjs';
import {Injectable} from '@angular/core';
import {ApiService} from '../api.service';
import {Cacheable, CacheBuster} from 'ts-cacheable';
import {DataResponse, ListResponse, SuccessResponse} from '../../models/response.model';
import {EquipmentCategory, EquipmentCategoryDetail} from '../../models/maintenance/equipment-category.model';

const cacheNotifier: Subject<void> = new Subject();

@Injectable({
  providedIn: 'root'
})
export class EquipmentCategoryService {

  constructor(private api: ApiService) {
  }

  getList(params?: object): Observable<ListResponse<EquipmentCategory>> {
    return this.api.get(`/maintenance/equipmentCategories`, params);
  }

  @Cacheable({
    cacheBusterObserver: cacheNotifier
  })
  getListDetail(params?: object): Observable<ListResponse<EquipmentCategoryDetail>> {
    return this.api.get(`/maintenance/equipmentCategory-list`, params);
  }

  getById(id: number): Observable<EquipmentCategory> {
    return this.api.get(`/maintenance/equipmentCategory/${id}`);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  create(data: object): Observable<DataResponse<EquipmentCategory>> {
    return this.api.post(`/maintenance/equipmentCategory`, data);
  }

  update(id: number, data: object): Observable<DataResponse<EquipmentCategory>> {
    return this.api.put(`/maintenance/equipmentCategory/${id}`, data);
  }

  @CacheBuster({
    cacheBusterNotifier: cacheNotifier
  })
  delete(id: number): Observable<SuccessResponse> {
    return this.api.delete(`/maintenance/equipmentCategory/${id}`);
  }

  addChecklistToCategory(id: number, data: object): Observable<SuccessResponse> {
    return this.api.put(`/maintenance/equipmentCategory/${id}/checklist`, data);
  }

  deleteChecklistFromCategory(id: number, data: object): Observable<SuccessResponse> {
    return this.api.delete(`/maintenance/equipmentCategory/${id}/checklist`, data);
  }

  getCategoryFromEquipment(data: object): Observable<EquipmentCategory> {
    return this.api.get(`/maintenance/equipmentCategory-equipment`, data);
  }

  static clearCache() {
    cacheNotifier.next();
  }
}
